<template>
  <div class="hello">
    <div class="page full">
      <div class="full public-web-container"  v-show="isMobile && !isWXWork && !isWeixin">
        <p class="">请复制链接到微信打开</p>
      </div>
      <div v-show="(isWXWork || isWeixin) && !flowWechat" class="full wechat-web-container" >
          <p class="">点击以下按钮打开 “{{app.mini_app_name}}”</p>
          <wx-open-launch-weapp @launch="showFlow" id="launch-btn" :username="app.mini_app_username" :path="app.mini_app_path">
          <script type="text/wxtag-template">
              <button style="width: 200px; height: 45px; text-align: center; font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px; border: none; border-radius: 4px; background-color: #07c160; color:#fff;">打开小程序</button>
          </script>
          </wx-open-launch-weapp>
      </div>
      <div class="full desktop-web-container" v-show="isDesktop">
          <p class="">请在手机打开网页链接</p>
      </div>
      <div class="flowWechat" v-show="(isWXWork || isWeixin) && flowWechat">
        <div class="modal_content">
          <p>本活动由公众号【免费小样】整理<br>加入线报群，第一时间接收线报</p>
          <img src="https://t.jekyll.top/9M5">
          <br>
          <br>
          <div><a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5NDA5NDk4MA==&scene=123#wechat_redirect">查看更多线报</a></div>
          <br>
          <div class="close_btn" @click="closeFlow">继续查看活动</div>
          <br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      link_id: '',
      isWXWork: navigator.userAgent.toLowerCase().match(/wxwork/i) == 'wxwork',
      isWeixin: !this.isWXWork && navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger',
      isMobile: false,
      isDesktop: false,
      app: {},
      flowWechat: false,
    }
  },
  created(){
    this.initId();
    this.init();
    this.show();
  },
  methods:{
    initId(){
      let url = window.location.href
      let p=url.split('?')[1]
      let params=new URLSearchParams(p)
      this.link_id = params.get('link')
    },
    showFlow(){
      if(this.app.flow_type == "1"){
        this.flowWechat = true;
      }
    },
    closeFlow(){
      this.flowWechat = false;
    },
    show: function(){
      this.axios.get("https://api.pages.yangmaoquan.com/api/mini_app?4=4&link_id=" + this.link_id).then((response) => {
        this.app = response.data;
        document.title = this.app.title + '-小程序转码';
        if(this.app.flow_type == "2"){
          this.flowWechat = true;
        }
      })
    },
    init: function(){
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
        this.isMobile = true
      } else {
        this.isDesktop = true
      }
      if (this.isWeixin) {
        wx.config({
          debug: false, // 调试时可开启
          appId: 'wx1d26c6b1617f994b', 
          timestamp: 0, // 必填，填任意数字即可
          nonceStr: 'nonceStr', // 必填，填任意非空字符串即可
          signature: 'signature', // 必填，填任意非空字符串即可
          jsApiList: ['chooseImage'], // 安卓上必填一个，随机即可
          openTagList:['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
        })
      } 
    }
  }
}
</script>

<style scoped>
      .hidden {
        display: none;
      }

      .full {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }

      .public-web-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .public-web-container p {
        position: absolute;
        top: 40%;
      }

      .public-web-container a {
        position: absolute;
        bottom: 40%;
      }

      .wechat-web-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .wechat-web-container p {
        position: absolute;
        top: 40%;
      }

      .wechat-web-container wx-open-launch-weapp {
        position: absolute;
        bottom: 40%;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .desktop-web-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .desktop-web-container p {
        position: absolute;
        top: 40%;
      }
      .flowWechat {
        background: gray;
        padding: 10%;
        height: 90vh;
        text-align: center;
      }
      .flowWechat img{
        width: 60vw;
        z-index: 1000;
      }
      .flowWechat .close_btn{
        width: 120px;
        height: 30px;
        text-align: center;
        margin: auto;
        background: #409EFF;
        color: white;
        line-height: 30px;
        border-radius: 3px;
      }
      .modal_content{
        background: white;
        padding: 10px;
        border-radius: 5px;
      }
</style>
