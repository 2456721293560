<template>
  <div id="app">
    <MiniApp />
  </div>
</template>

<script>
import MiniApp from './components/MiniApp.vue'

export default {
  name: 'App',
  components: {
    MiniApp
  }
}
</script>